import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './lib/store';
import { useAppDispatch, useAppSelector, useTheme } from './lib/hooks';
import { checkAuth } from './lib/features/auth/authSlice';
import TaskScheduler from './components/TaskScheduler';
import AuthForms from './components/Auth/AuthForm';
import NavBar from './components/NavBar';
import Loader from './components/Loader';

const ProtectedRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { isAuthenticated, loading } = useAppSelector(state => state.auth);
  const token = localStorage.getItem('token');
  const hasToken = !!token;

  if (loading || (hasToken && !isAuthenticated)) {
    return <Loader message="Checking authentication..." />;
  }

  return isAuthenticated ? <>{element}</> : <Navigate to="/login" />;
};

const AppWrapper: React.FC = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
};

const AppContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { theme, toggleTheme } = useTheme();
  const { isAuthenticated, loading } = useAppSelector(state => state.auth);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  return (
    <Router>
      <div className={`min-h-screen ${theme === 'dark' ? 'dark' : ''}`}>
        <NavBar theme={theme} toggleTheme={toggleTheme} />
        <Routes>
          <Route path="/login" element={
            isAuthenticated 
              ? <Navigate to="/" /> 
              : <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
                  <AuthForms onSuccess={() => {}} />
                </div>
          } />
          <Route path="/" element={
            <ProtectedRoute element={<TaskScheduler theme={theme} />} />
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default AppWrapper;