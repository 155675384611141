import React from 'react';
import { MdLightMode, MdDarkMode } from 'react-icons/md';

interface ThemeToggleProps {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ theme, toggleTheme }) => {
  return (
    <button
      onClick={toggleTheme}
      className="p-2 rounded-full dark:bg-gray-700 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
      aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
      type="button"
    >
      {theme === 'light' ? (
        <MdDarkMode className="text-gray-700 w-5 h-5" />
      ) : (
        <MdLightMode className="text-yellow-300 w-5 h-5" />
      )}
    </button>
  );
};

export default ThemeToggle;