import React from 'react';
import { format } from 'date-fns';
import { isAllDayEvent, formatEventTimeRange, getStatusColor, getStatusText } from '../utils/taskUtils';
import { TaskUI } from '../types';

interface TaskDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: TaskUI;
  onEdit: () => void;
  onDelete: () => void;
  onStatusChange: (taskId: string, newStatus: 'pending' | 'in_progress' | 'completed' | 'cancelled') => void;
  theme?: 'light' | 'dark';
}

const TaskDetailsModal: React.FC<TaskDetailsModalProps> = ({
  isOpen,
  onClose,
  task,
  onEdit,
  onDelete,
  onStatusChange,
  theme = 'light'
}) => {
  if (!isOpen) return null;

  const handleStatusChange = (newStatus: 'pending' | 'in_progress' | 'completed' | 'cancelled') => {
    onStatusChange(task.id, newStatus);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-900 rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl dark:text-white font-semibold">Task Details</h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 dark:text-white hover:text-gray-700"
            aria-label="Close modal"
            type="button"
          >
            ✕
          </button>
        </div>
        
        <div className="space-y-4">
          <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
            <div className="text-lg dark:text-white font-medium mb-2">{task.title}</div>
            
            <div className="text-sm dark:text-white text-gray-600 mb-2">
              {format(task.start, 'EEEE, MMMM d, yyyy')}
            </div>
            
            <div className="text-sm font-medium mb-2">
              {isAllDayEvent(task) 
                ? 'All Day' 
                : formatEventTimeRange(task)
              }
            </div>
            
            <div className="mb-3">
              <span className={`inline-block px-2 py-1 text-xs rounded-full ${getStatusColor(task.status)}`}>
                {getStatusText(task.status)}
              </span>
            </div>
            
            {task.description && (
              <div className="text-sm dark:text-white text-gray-700 mt-2 border-t pt-2">
                {task.description}
              </div>
            )}
          </div>
          
          <div className="space-y-2">
            <p className="text-sm font-medium">Update Status:</p>
            <div className="flex flex-wrap gap-2">
              <button
                className={`px-3 py-1 text-xs rounded ${
                  task.status === 'pending' ? 'bg-gray-200' : 'bg-gray-100 hover:bg-gray-200'
                }`}
                onClick={() => handleStatusChange('pending')}
                disabled={task.status === 'pending'}
                type="button"
              >
                Pending
              </button>
              <button
                className={`px-3 py-1 text-xs rounded ${
                  task.status === 'in_progress' ? 'bg-yellow-200' : 'bg-yellow-100 hover:bg-yellow-200'
                }`}
                onClick={() => handleStatusChange('in_progress')}
                disabled={task.status === 'in_progress'}
                type="button"
              >
                In Progress
              </button>
              <button
                className={`px-3 py-1 text-xs rounded ${
                  task.status === 'completed' ? 'bg-green-200' : 'bg-green-100 hover:bg-green-200'
                }`}
                onClick={() => handleStatusChange('completed')}
                disabled={task.status === 'completed'}
                type="button"
              >
                Completed
              </button>
              <button
                className={`px-3 py-1 text-xs rounded ${
                  task.status === 'cancelled' ? 'bg-red-200' : 'bg-red-100 hover:bg-red-200'
                }`}
                onClick={() => handleStatusChange('cancelled')}
                disabled={task.status === 'cancelled'}
                type="button"
              >
                Cancelled
              </button>
            </div>
          </div>
        
          <div className="flex justify-end space-x-2 pt-2">
            <button
              className="px-4 py-2 text-sm rounded text-red-600 hover:bg-red-50"
              onClick={onDelete}
              type="button"
            >
              Delete
            </button>
            <button
              className="px-4 py-2 text-sm rounded bg-gray-100 hover:bg-gray-200"
              onClick={onEdit}
              type="button"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsModal;