import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import type { RootState, AppDispatch } from './store';
import { toggleTheme, setTheme } from './features/theme/themeSlice';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDebounce = <T>(value: T, delay: number = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useTheme = () => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.theme);

  const toggleThemeHandler = useCallback(() => {
    dispatch(toggleTheme());
  }, [dispatch]);

  const setThemeHandler = useCallback((newTheme: 'light' | 'dark') => {
    dispatch(setTheme(newTheme));
  }, [dispatch]);

  return { 
    theme, 
    toggleTheme: toggleThemeHandler, 
    setTheme: setThemeHandler 
  };
};

export const useFetchTasks = () => {
  const dispatch = useAppDispatch();
  const { tasks, loading, error } = useAppSelector((state) => state.tasks);
  
  const fetchTasksHandler = useCallback(() => {
    const { fetchTasks } = require('./features/tasks/tasksSlice');
    dispatch(fetchTasks());
  }, [dispatch]);
  
  return { 
    tasks, 
    loading, 
    error, 
    fetchTasks: fetchTasksHandler 
  };
};