// filepath: task-scheduler-frontend/src/components/TaskScheduler.tsx
import React, { useState, useEffect } from 'react';
import { MdAdd, MdViewWeek } from 'react-icons/md';
import Calendar from './Calendar';
import Loader from './Loader';
import Dashboard from './Dashboard';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { fetchTasks } from '../lib/features/tasks/tasksSlice';

interface TaskSchedulerProps {
  theme?: 'light' | 'dark';
}

const TaskScheduler: React.FC<TaskSchedulerProps> = ({ theme = 'light' }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
  const { tasks, loading, error } = useAppSelector(state => state.tasks);
  const { user } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (tasks.length === 0) {
      dispatch(fetchTasks());
    }
  }, [dispatch, tasks.length]);

  const handleOpenCalendar = () => {
    setIsCalendarOpen(true);
  };

  const handleCloseCalendar = () => {
    setIsCalendarOpen(false);
    dispatch(fetchTasks());
  };

  return (
    <div className="p-16 dark:bg-gray-700">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white transition-colors duration-200">
          My Dashboard
        </h2>
        <div className="flex space-x-3">
          <button
            onClick={handleOpenCalendar}
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 flex items-center gap-2 text-white rounded-md transition-colors duration-200"
            type="button"
          >
            <MdViewWeek className="w-5 h-5" /> View Calendar
          </button>
          <button
            onClick={handleOpenCalendar}
            className="px-4 py-2 bg-green-500 hover:bg-green-600 flex items-center gap-2 text-white rounded-md transition-colors duration-200"
            type="button"
          >
            <MdAdd className="w-5 h-5" /> New Task
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-12">
          <Loader message="Loading your dashboard..." theme={theme} />
        </div>
      ) : (
        <Dashboard tasks={tasks} theme={theme} />
      )}

      {isCalendarOpen && (
        <div className="fixed inset-0 z-50 bg-white dark:bg-gray-900 overflow-hidden transition-colors duration-200">
          <div className="w-full h-screen">
            <Calendar 
              onClose={handleCloseCalendar} 
              theme={theme}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskScheduler;