import React from 'react';

interface LoaderProps {
  message?: string;
  theme?: 'light' | 'dark';
}

const Loader: React.FC<LoaderProps> = ({ message = 'Loading...', theme = 'light' }) => {
  return (
    <div className="absolute inset-0 bg-white dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center z-50 transition-colors duration-200">
      <div className="flex flex-col items-center">
        <div className="relative w-12 h-12">
          <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 dark:border-blue-400 border-opacity-20 dark:border-opacity-20 rounded-full transition-colors duration-200"></div>
          <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 dark:border-blue-400 border-l-transparent rounded-full animate-spin transition-colors duration-200"></div>
        </div>
        <span className="mt-4 text-sm text-blue-500 dark:text-blue-400 font-medium transition-colors duration-200">
          {message}
        </span>
      </div>
    </div>
  );
};

export default Loader;