import React from 'react';
import { cn } from '../utils/cn';

interface ColorPickerProps {
  selectedColor: string;
  onColorSelect: (color: string) => void;
  theme?: 'light' | 'dark';
}

const ColorPicker: React.FC<ColorPickerProps> = ({ selectedColor, onColorSelect, theme = 'light' }) => {
  const colors: Record<string, string> = {
    blue: 'bg-[#D6F2FE]',
    pink: 'bg-[#FDE6EC]',
    purple: 'bg-[#F0EAF8]',
    green: 'bg-[#c5e3d4]',
    orange: 'bg-orange-100',
    red: 'bg-red-100'
  };

  return (
    <div className="flex gap-2 mb-4">
      {Object.entries(colors).map(([color, className]) => (
        <button
          key={color}
          aria-label={`Select ${color} color`}
          className={cn(
            'w-6 h-6 rounded-full transition-all',
            className,
            selectedColor === color ? 'ring-2 ring-offset-2 ring-blue-500' : ''
          )}
          onClick={() => onColorSelect(color)}
          type="button"
        />
      ))}
    </div>
  );
};

export default ColorPicker;