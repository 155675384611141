import axiosInstance from './axiosInstance';
import { Task, CreateTaskRequest, UpdateTaskRequest } from '../types';

export const taskAPI = {
  getTasks: async (): Promise<Task[]> => {
    const response = await axiosInstance.get<{ status: string; data: Task[] }>('/tasks');
    console.log(response.data.data);
    return response.data.data;
  },
  
  getTaskById: async (id: string): Promise<Task> => {
    const response = await axiosInstance.get<{ status: string; data: Task }>(`/tasks/${id}`);
    return response.data.data;
  },
  
  createTask: async (taskData: CreateTaskRequest): Promise<Task> => {
    const response = await axiosInstance.post<{ status: string; data: Task }>('/tasks', taskData);
    return response.data.data;
  },
  
  updateTask: async (id: string, taskData: UpdateTaskRequest): Promise<Task> => {
    const response = await axiosInstance.patch<{ status: string; data: Task }>(`/tasks/${id}`, taskData);
    return response.data.data;
  },
  
  deleteTask: async (id: string): Promise<void> => {
    await axiosInstance.delete(`/tasks/${id}`);
  }
}