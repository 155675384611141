import { Task, TaskUI, CreateTaskRequest, UpdateTaskRequest } from '../types';

/**
 * Convert API Task format to UI TaskUI format (with Date objects)
 */
export const apiToUiTask = (task: Task): TaskUI => {
  return {
    id: task.id,
    title: task.title,
    description: task.description,
    start: new Date(task.startTime),
    end: new Date(task.endTime),
    color: task.color,
    status: task.status
  };
};

/**
 * Convert UI TaskUI format to API Task format (with ISO strings)
 */
export const uiToApiTask = (task: TaskUI): Task => {
  return {
    id: task.id,
    title: task.title,
    description: task.description,
    startTime: task.start.toISOString(),
    endTime: task.end.toISOString(),
    color: task.color,
    status: task.status
  };
};

/**
 * Convert UI TaskUI format to API CreateTaskRequest
 */
export const uiToCreateTaskRequest = (task: {
  title: string;
  description?: string;
  start: Date;
  end: Date;
  color: string;
  status?: 'pending' | 'in_progress' | 'completed' | 'cancelled';
}): CreateTaskRequest => {
  return {
    title: task.title,
    description: task.description,
    startTime: task.start.toISOString(),
    endTime: task.end.toISOString(),
    color: task.color,
    status: task.status || 'pending'
  };
};

/**
 * Convert UI TaskUI format to API UpdateTaskRequest
 */
export const uiToUpdateTaskRequest = (task: {
  title?: string;
  description?: string;
  start?: Date;
  end?: Date;
  color?: string;
  status?: 'pending' | 'in_progress' | 'completed' | 'cancelled';
}): UpdateTaskRequest => {
  const request: UpdateTaskRequest = {};
  
  if (task.title !== undefined) request.title = task.title;
  if (task.description !== undefined) request.description = task.description;
  if (task.start !== undefined) request.startTime = task.start.toISOString();
  if (task.end !== undefined) request.endTime = task.end.toISOString();
  if (task.color !== undefined) request.color = task.color;
  if (task.status !== undefined) request.status = task.status;
  
  return request;
};

/**
 * Convert array of API Tasks to UI TaskUIs
 */
export const convertTasksForUI = (tasks: Task[]): TaskUI[] => {
  return tasks.map(apiToUiTask);
};