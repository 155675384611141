import { jwtDecode } from 'jwt-decode';
import axiosInstance from './axiosInstance';
import { AuthResponse, LoginRequest, RegisterRequest } from '../types';

export const authAPI = {
  register: async (userData: RegisterRequest): Promise<AuthResponse> => {
    const response = await axiosInstance.post<AuthResponse>('/auth/register', userData);
    return response.data;
  },
  
  login: async (credentials: LoginRequest): Promise<AuthResponse> => {
    const response = await axiosInstance.post<AuthResponse>('/auth/login', credentials);
    console.log('Login response:', response.data); 
    if (response.data.data.token) {
      console.log('Storing token:', response.data.data.token); 
      localStorage.setItem('token', response.data.data.token);
      console.log('Token stored in localStorage:', localStorage.getItem('token')); 
    }
    return response.data;
  },
  
  logout: (): void => {
    localStorage.removeItem('token');
  },
  
  getCurrentUser: async (): Promise<AuthResponse | null> => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    
    try {
      const decodedToken: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        return null;
      }
      
      return {
        data: {
          token,
          user: {
            id: decodedToken.id,
            email: decodedToken.email,
          }
        }
      } as AuthResponse;
    } catch (error) {
      console.error('Error verifying authentication:', error);
      localStorage.removeItem('token');
      return null;
    }
  }
};