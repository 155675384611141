import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { format, addMonths, subMonths } from 'date-fns';
import { cn } from '../utils/cn';

interface MiniCalendarProps {
  selectedDate: Date;
  onDateSelect: (date: Date) => void;
  currentMonth: Date;
  onMonthChange: (date: Date) => void;
  displayedWeek: Date[];
  theme?: 'light' | 'dark';
}

const MiniCalendar: React.FC<MiniCalendarProps> = ({
  selectedDate,
  onDateSelect,
  currentMonth,
  onMonthChange,
  displayedWeek,
  theme = 'light'
}) => {
  const [isMonthDropdownOpen, setMonthDropdownOpen] = useState<boolean>(false);
  const [isYearDropdownOpen, setYearDropdownOpen] = useState<boolean>(false);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  const years = Array.from(
    { length: 20 },
    (_, index) => new Date().getFullYear() - 10 + index
  );

  const generateCalendarDays = () => {
    const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const startingDay = new Date(firstDayOfMonth);
    startingDay.setDate(1 - firstDayOfMonth.getDay());

    const today = new Date();
    
    const days = [];
    for (let i = 0; i < 42; i++) {
      const currentDate = new Date(startingDay);
      currentDate.setDate(startingDay.getDate() + i);

      const isInDisplayedWeek = displayedWeek && displayedWeek.some(date => 
        date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getDate() === currentDate.getDate()
      );

      days.push({
        date: currentDate,
        isCurrentMonth: currentDate.getMonth() === currentMonth.getMonth(),
        isSelected: currentDate.getFullYear() === selectedDate.getFullYear() &&
                    currentDate.getMonth() === selectedDate.getMonth() &&
                    currentDate.getDate() === selectedDate.getDate(),
        isToday: currentDate.getFullYear() === today.getFullYear() &&
                 currentDate.getMonth() === today.getMonth() &&
                 currentDate.getDate() === today.getDate(),
        isInDisplayedWeek: isInDisplayedWeek
      });
    }

    return days;
  };

  const days = generateCalendarDays();

  const handlePrevMonth = () => {
    onMonthChange(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    onMonthChange(addMonths(currentMonth, 1));
  };

  const handleMonthSelect = (monthIndex: number) => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(monthIndex);
    onMonthChange(newDate);
    setMonthDropdownOpen(false);
  };

  const handleYearSelect = (year: number) => {
    const newDate = new Date(currentMonth);
    newDate.setFullYear(year);
    onMonthChange(newDate);
    setYearDropdownOpen(false);
  };

  const getDayPosition = (date: Date) => {
    const dayOfWeek = date.getDay();
    return {
      isStart: dayOfWeek === 0,
      isEnd: dayOfWeek === 6,  
    };
  };

  return (
    <div className="w-[300px] p-5 bg-white dark:bg-gray-800 rounded-2xl shadow-sm transition-colors duration-200">
      <div className="flex items-center justify-between mb-6">
        <div className="relative flex gap-2">
          <button 
            onClick={() => setMonthDropdownOpen(!isMonthDropdownOpen)}
            className="flex text-[13px] items-center gap-1 font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg px-2 py-1 transition-colors duration-200"
            aria-label="Select month"
            type="button"
          >
            {format(currentMonth, 'MMMM')}
            {isMonthDropdownOpen ? 
              <FaCaretUp size={16} className="text-gray-400 dark:text-gray-500" /> : 
              <FaCaretDown size={16} className="text-gray-400 dark:text-gray-500" />
            }
          </button>
          
          {isMonthDropdownOpen && (
            <div className="absolute text-[14px] top-full mt-1 w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg z-50 transition-colors duration-200">
              {months.map((month, index) => (
                <button
                  key={month}
                  onClick={() => handleMonthSelect(index)}
                  className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                  type="button"
                >
                  {month}
                </button>
              ))}
            </div>
          )}

          <button 
            onClick={() => setYearDropdownOpen(!isYearDropdownOpen)}
            className="flex gap-1 text-[13px] font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg px-2 py-1 transition-colors duration-200"
            type="button"
          >
            {format(currentMonth, 'yyyy')}
            {isYearDropdownOpen ? 
              <FaCaretUp size={16} className="text-gray-400 dark:text-gray-500" /> : 
              <FaCaretDown size={16} className="text-gray-400 dark:text-gray-500" />
            }
          </button>
          
          {isYearDropdownOpen && (
            <div className="absolute text-[14px] top-full mt-1 w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg z-50 transition-colors duration-200">
              {years.map((year) => (
                <button
                  key={year}
                  onClick={() => handleYearSelect(year)}
                  className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                  type="button"
                >
                  {year}
                </button>
              ))}
            </div>
          )}
        </div>
        
        <div className="flex gap-1">
          <button
            onClick={handlePrevMonth}
            className="p-2 bg-gray-100 dark:bg-gray-700 rounded-full transition-colors duration-200"
            aria-label="Previous month"
            type="button"
          >
            <ChevronLeft size={16} className="text-gray-600 dark:text-gray-300" />
          </button>
          <button
            onClick={handleNextMonth}
            className="p-2 bg-gray-100 dark:bg-gray-700 rounded-full transition-colors duration-200"
            aria-label="Next month"
            type="button"
          >
            <ChevronRight size={16} className="text-gray-600 dark:text-gray-300" />
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-7">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center text-[11px] text-gray-600 dark:text-gray-400 py-1 transition-colors duration-200">
            {day}
          </div>
        ))}
      </div>
      
      <div className="grid grid-cols-7">
        {days.map((day, idx) => {
          const { isStart, isEnd } = getDayPosition(day.date);
          
          return (
            <button
              key={idx}
              onClick={() => onDateSelect(day.date)}
              className={cn(
                "aspect-square flex items-center justify-center text-[12px]",
                "transition-colors relative",
                !day.isCurrentMonth ? "text-gray-400 dark:text-gray-600" : "text-gray-900 dark:text-gray-100",
                day.isSelected ? "bg-blue-500 text-white font-medium z-10 rounded-lg" : "",
                day.isToday && !day.isSelected ? "text-blue-500 dark:text-blue-400 font-medium" : "",
                day.isInDisplayedWeek && !day.isSelected ? "bg-slate-100 dark:bg-slate-700" : "",
                day.isInDisplayedWeek && isStart && !day.isSelected ? "rounded-l-lg" : "",
                day.isInDisplayedWeek && isEnd && !day.isSelected ? "rounded-r-lg" : "",
                !day.isSelected && "hover:bg-gray-200 dark:hover:bg-gray-600 hover:z-10 hover:rounded-lg"
              )}
              aria-current={day.isToday ? "date" : undefined}
              type="button"
            >
              {day.date.getDate()}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MiniCalendar;