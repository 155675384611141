import React, { useState, useMemo, useCallback } from 'react';
import { format, setHours, differenceInMinutes, isWithinInterval, isSameDay } from 'date-fns';
import { cn } from '../utils/cn';
import { isAllDayEvent, formatEventTimeRange, getStatusColor, getStatusText } from '../utils/taskUtils';
import TaskDetailsModal from './TaskDetailsModal';
import { TaskUI } from '../types';

interface TimeGridProps {
  weekDays: Date[];
  tasks: TaskUI[];
  onTimeSlotClick: (time: Date) => void;
  colors: Record<string, string>;
  onTaskUpdate: (task: TaskUI) => void;
  onTaskDelete: (taskId: string) => void;
  onTaskStatusChange: (taskId: string, newStatus: 'pending' | 'in_progress' | 'completed' | 'cancelled') => void;
  selectedDay: Date | null;
  theme?: 'light' | 'dark';
}

const TimeGrid: React.FC<TimeGridProps> = ({
  weekDays,
  tasks,
  onTimeSlotClick,
  colors,
  onTaskUpdate,
  onTaskDelete,
  onTaskStatusChange,
  selectedDay,
  theme = 'light'
}) => {
  const [selectedTask, setSelectedTask] = useState<TaskUI | null>(null);
  const [showTaskDetails, setShowTaskDetails] = useState<boolean>(false);

  const hours = useMemo(() => Array.from({ length: 24 }, (_, i) => i), []);


  const doTasksOverlap = useCallback((task1: TaskUI, task2: TaskUI): boolean => {
    return isWithinInterval(task1.start, {
      start: task2.start,
      end: task2.end,
    }) || isWithinInterval(task1.end, {
      start: task2.start,
      end: task2.end,
    });
  }, []);

 
  const getTaskColumn = useCallback((task: TaskUI, dayTasks: TaskUI[]): number => {
    const overlappingTasks = dayTasks.filter(t => t.id !== task.id && doTasksOverlap(task, t));
    if (overlappingTasks.length === 0) return 0;

    let currentColumn = 0;
    while (overlappingTasks.some(t => (t as any).column === currentColumn)) {
      currentColumn++;
    }
    return currentColumn;
  }, [doTasksOverlap]);

 
  const getTaskStyles = useCallback((task: TaskUI & { column?: number }, totalColumns: number): React.CSSProperties => {
    const durationHours = differenceInMinutes(task.end, task.start) / 60;
    const startPosition = task.start.getHours() + task.start.getMinutes() / 60;
    
    return {
      height: `${durationHours * 64}px`,
      top: `${startPosition * 64}px`,
      width: `calc(100% / ${totalColumns})`,
      left: `${((task.column || 0) * 100) / totalColumns}%`
    };
  }, []);

  
  const handleTaskClick = useCallback((task: TaskUI, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedTask(task);
    setShowTaskDetails(true);
  }, []);

  
  const handleEditTask = useCallback(() => {
    if (selectedTask) {
      onTaskUpdate(selectedTask);
    }
    setShowTaskDetails(false);
  }, [selectedTask, onTaskUpdate]);

  const handleDeleteTask = useCallback(() => {
    if (selectedTask) {
      onTaskDelete(selectedTask.id);
    }
    setShowTaskDetails(false);
  }, [selectedTask, onTaskDelete]);


  const handleStatusChange = useCallback((taskId: string, newStatus: 'pending' | 'in_progress' | 'completed' | 'cancelled') => {
    onTaskStatusChange(taskId, newStatus);
    setShowTaskDetails(false);
  }, [onTaskStatusChange]);

  return (
    <>
      <div className="grid font-sans grid-cols-8">
        <div className="w-16 mt-4">
          {hours.map(hour => (
            <div
              key={hour}
              className="h-16 mt-auto text-right pr-2 text-sm text-[#6B6B6B] dark:text-gray-400 transition-colors duration-200"
              aria-label={`${format(setHours(new Date(), hour), 'ha')} time slot`}
            >
              {format(setHours(new Date(), hour), 'ha')}
            </div>
          ))}
        </div>

        {weekDays.map(day => {
          const dayTasks = tasks
            .filter(task => isSameDay(task.start, day))
            .map(task => ({ ...task, column: getTaskColumn(task, tasks) }));

          const isSelectedDay = selectedDay && isSameDay(day, selectedDay);

          return (
            <div
              key={day.toISOString()}
              className={`relative border border-b-0 border-l border-gray-200 dark:border-gray-600 ${
                isSelectedDay ? 'bg-blue-50/30 dark:bg-blue-900/10' : ''
              } transition-colors duration-200`}
            >
              {hours.map(hour => {
                const isEvenHour = hour % 2 === 0;
                
                return (
                  <div
                    key={hour}
                    className={cn(
                      'h-16 border-b border-gray-200 dark:border-gray-600',
                      isEvenHour ? 'bg-transparent dark:bg-transparent' : 'bg-gray-50/50 dark:bg-gray-800/30',
                      'hover:bg-gray-100 dark:hover:bg-gray-700/50 cursor-pointer transition-colors duration-200'
                    )}
                    onClick={() => onTimeSlotClick(setHours(day, hour))}
                    role="button"
                    aria-label={`Select time slot at ${format(setHours(day, hour), 'ha')}`}
                    tabIndex={0}
                  >
                    <div className="w-full h-full relative">
                      <div className="absolute inset-0 border-t border-gray-100 dark:border-gray-700/50 pointer-events-none"></div>
                    </div>
                  </div>
                );
              })}

              {dayTasks.map(task => {
                const totalColumns = Math.max(...dayTasks.map(t => (t as any).column || 0)) + 1;
                const taskStyles = getTaskStyles(task as TaskUI & { column?: number }, totalColumns);
                const bgColor = colors[task.color] || colors['blue'];

                return (
                  <div
                    key={task.id}
                    className={cn(
                      'absolute rounded-[4px] px-1 cursor-pointer hover:opacity-90 shadow-sm dark:shadow-md',
                      bgColor,
                      'transition-colors duration-200'
                    )}
                    style={taskStyles}
                    onClick={(e) => handleTaskClick(task, e)}
                    role="button"
                    aria-label={`Task: ${task.title} - ${formatEventTimeRange(task)}`}
                    tabIndex={0}
                  >
                    <div className="h-full rounded p-1 text-[12px] overflow-hidden">
                      <div className="font-medium mb-0.5 truncate">{task.title}</div>
                      <div className="text-[11px] opacity-90">
                        {isAllDayEvent(task) ? 'All Day' : formatEventTimeRange(task)}
                      </div>
                      <div className="mt-1">
                        <span className={`inline-block px-1.5 py-0.5 text-[10px] rounded-full ${
                          theme === 'dark' 
                            ? getStatusColor(task.status).replace('bg-', 'dark:bg-').replace('text-', 'dark:text-')
                            : getStatusColor(task.status)
                        }`}>
                          {getStatusText(task.status)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {selectedTask && (
        <TaskDetailsModal
          isOpen={showTaskDetails}
          onClose={() => setShowTaskDetails(false)}
          task={selectedTask}
          onEdit={handleEditTask}
          onDelete={handleDeleteTask}
          onStatusChange={handleStatusChange}
          theme={theme}
        />
      )}
    </>
  );
};

export default TimeGrid;