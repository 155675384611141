import React, { useState } from 'react';
import { format, isToday, isThisWeek, isPast, differenceInDays } from 'date-fns';
import { getStatusColor, getStatusText } from '../utils/taskUtils';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { MdToday, MdDateRange, MdCheckCircle, MdAccessTime, MdCancel } from 'react-icons/md';
import { Task } from '../types';
import { convertTasksForUI } from '../utils/taskHelpers';

interface DashboardProps {
  tasks: Task[];
  theme?: 'light' | 'dark';
}

type FilterType = 'today' | 'week' | 'all' | 'completed' | 'pending' | 'overdue';

interface StatusData {
  name: string;
  value: number;
  color: string;
}

const Dashboard: React.FC<DashboardProps> = ({ tasks, theme = 'light' }) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterType>('today');
  
  const uiTasks = convertTasksForUI(tasks);
  
  const filteredTasks = uiTasks.filter(task => {
    if (selectedFilter === 'today') return isToday(task.start);
    if (selectedFilter === 'week') return isThisWeek(task.start);
    if (selectedFilter === 'all') return true;
    if (selectedFilter === 'completed') return task.status === 'completed';
    if (selectedFilter === 'pending') return task.status === 'pending';
    if (selectedFilter === 'overdue') 
      return isPast(task.end) && task.status !== 'completed' && task.status !== 'cancelled';
    return true;
  });
  
  filteredTasks.sort((a, b) => a.start.getTime() - b.start.getTime());
  
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(task => task.status === 'completed').length;
  const pendingTasks = tasks.filter(task => task.status === 'pending').length;
  const inProgressTasks = tasks.filter(task => task.status === 'in_progress').length;
  const cancelledTasks = tasks.filter(task => task.status === 'cancelled').length;
  const overdueTasks = uiTasks.filter(task => 
    isPast(task.end) && task.status !== 'completed' && task.status !== 'cancelled'
  ).length;
  
  const statusData: StatusData[] = [
    { name: 'Completed', value: completedTasks, color: '#4ade80' },
    { name: 'In Progress', value: inProgressTasks, color: '#facc15' },
    { name: 'Pending', value: pendingTasks, color: '#9ca3af' },
    { name: 'Cancelled', value: cancelledTasks, color: '#f87171' },
    { name: 'Overdue', value: overdueTasks, color: '#ef4444' },
  ].filter(item => item.value > 0);
  
  const hasUpcomingTasks = uiTasks.some(task => 
    !isToday(task.start) && 
    isThisWeek(task.start) && 
    !isPast(task.start) && 
    task.status !== 'completed' && 
    task.status !== 'cancelled'
  );

  return (
    <div className="space-y-6">
      {/* Stats cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-md p-4 border-l-4 border-blue-500">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-500 text-sm">Total Tasks</p>
              <h3 className="text-2xl font-bold">{totalTasks}</h3>
            </div>
            <MdDateRange className="text-blue-500 text-3xl" />
          </div>
        </div>
        
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-md p-4 border-l-4 border-green-500">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-500 text-sm">Completed</p>
              <h3 className="text-2xl font-bold">{completedTasks}</h3>
            </div>
            <MdCheckCircle className="text-green-500 text-3xl" />
          </div>
        </div>
        
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-md p-4 border-l-4 border-red-500">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-500 text-sm">Overdue</p>
              <h3 className="text-2xl font-bold">{overdueTasks}</h3>
            </div>
            <MdAccessTime className="text-red-500 text-3xl" />
          </div>
        </div>
      </div>
      
      {totalTasks > 0 && (
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Task Status Distribution</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={statusData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                >
                  {statusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={(value: number) => [`${value} task(s)`, 'Count']} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
      
      <div className="bg-white dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold">Your Tasks</h2>
          <div className="flex space-x-2">
            <button
              onClick={() => setSelectedFilter('today')}
              className={`px-3 py-1 text-sm rounded-full ${
                selectedFilter === 'today' 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              type="button"
            >
              Today
            </button>
            <button
              onClick={() => setSelectedFilter('week')}
              className={`px-3 py-1 text-sm rounded-full ${
                selectedFilter === 'week' 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              type="button"
            >
              This Week
            </button>
            <button
              onClick={() => setSelectedFilter('completed')}
              className={`px-3 py-1 text-sm rounded-full ${
                selectedFilter === 'completed' 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              type="button"
            >
              Completed
            </button>
            <button
              onClick={() => setSelectedFilter('overdue')}
              className={`px-3 py-1 text-sm rounded-full ${
                selectedFilter === 'overdue' 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              type="button"
            >
              Overdue
            </button>
          </div>
        </div>
        
        {filteredTasks.length === 0 ? (
          <div className="text-center py-8">
            <MdToday className="mx-auto text-gray-300 text-5xl mb-2" />
            <p className="text-gray-500">
              {selectedFilter === 'today' ? 'No tasks scheduled for today' : 
               selectedFilter === 'week' ? 'No tasks scheduled for this week' :
               selectedFilter === 'completed' ? 'No completed tasks yet' :
               selectedFilter === 'overdue' ? 'No overdue tasks, great job!' :
               'No tasks found'}
            </p>
            {selectedFilter === 'today' && hasUpcomingTasks && (
              <p className="text-sm text-blue-500 mt-2">You have tasks coming up later this week.</p>
            )}
          </div>
        ) : (
          <div className="space-y-3">
            {filteredTasks.map(task => {
              const isTaskOverdue = isPast(task.end) && task.status !== 'completed' && task.status !== 'cancelled';
              const daysLeft = differenceInDays(task.start, new Date());
              
              return (
                <div 
                  key={task.id} 
                  className={`flex items-center justify-between p-4 border rounded-md ${
                    isTaskOverdue ? 'border-red-200 bg-red-50 text-gray-900' : 'hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <div className={`w-3 h-3 rounded-full ${
                      task.color === 'blue' ? 'bg-blue-500' : 
                      task.color === 'green' ? 'bg-green-500' : 
                      task.color === 'pink' ? 'bg-pink-500' : 
                      task.color === 'purple' ? 'bg-purple-500' : 
                      task.color === 'orange' ? 'bg-orange-500' : 'bg-red-500'
                    }`} />
                    <div>
                      <h3 className="font-medium">{task.title}</h3>
                      <div className="flex space-x-2 text-sm text-gray-500">
                        <p>{format(task.start, 'MMM d, yyyy')}</p>
                        <p>•</p>
                        <p>{format(task.start, 'h:mm a')} - {format(task.end, 'h:mm a')}</p>
                      </div>
                      {isTaskOverdue && (
                        <p className="text-xs text-red-600 mt-1">Overdue by {Math.abs(daysLeft)} day(s)</p>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(task.status)}`}>
                      {getStatusText(task.status)}
                    </span>
                    {task.status === 'cancelled' && <MdCancel className="text-red-500" />}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;