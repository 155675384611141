import { format } from 'date-fns';
import { TaskUI } from '../types';

/**
 * Checks if a task is an all-day event
 */
export const isAllDayEvent = (task: TaskUI): boolean => {
  return (
    task.start.getHours() === 0 &&
    task.start.getMinutes() === 0 &&
    task.end.getHours() === 23 &&
    task.end.getMinutes() === 59
  );
};

/**
 * Formats the time range of an event
 */
export const formatEventTimeRange = (task: TaskUI): string => {
  return `${format(task.start, 'h:mm a')} - ${format(task.end, 'h:mm a')}`;
};

/**
 * Gets the appropriate color class for a task status
 */
export const getStatusColor = (status: string): string => {
  switch(status) {
    case 'in_progress':
      return 'bg-yellow-100 text-yellow-800';
    case 'completed':
      return 'bg-green-100 text-green-800';
    case 'cancelled':
      return 'bg-red-100 text-red-800';
    case 'pending':
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

/**
 * Gets the display text for a task status
 */
export const getStatusText = (status: string): string => {
  switch(status) {
    case 'in_progress':
      return 'In Progress';
    case 'completed':
      return 'Completed';
    case 'cancelled':
      return 'Cancelled';
    case 'pending':
    default:
      return 'Pending';
  }
};