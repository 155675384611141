import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { logout } from '../lib/features/auth/authSlice';
import ThemeToggle from './ThemeToggle';
import { FaUserCircle} from 'react-icons/fa';

interface NavBarProps {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

const NavBar: React.FC<NavBarProps> = ({ theme, toggleTheme }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.auth);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-md p-4 px-16 flex justify-between items-center">
      <div className="text-xl font-bold text-gray-800 dark:text-white">
        Task Scheduler
      </div>
      <div className="flex items-center space-x-4">
      
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
        {user && (
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setDropdownOpen(!isDropdownOpen)}
              className="flex items-center space-x-2 text-gray-800 dark:text-white"
            >
              <FaUserCircle size={24} />
              <span>{user.email}</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md shadow-lg py-2">
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;