import React from 'react';
import { format, isSameDay } from 'date-fns';

interface WeekHeaderProps {
  weekDays: Date[];
  selectedDay: Date | null;
  theme?: 'light' | 'dark';
}

const WeekHeader: React.FC<WeekHeaderProps> = ({ weekDays, selectedDay, theme = 'light' }) => (
  <div className="font-sans sticky top-0 z-10 bg-white dark:bg-gray-900 transition-colors duration-200">
    <div className="grid grid-cols-8">
      <div className="w-16" />
      {weekDays.map(day => {
        const isSelected = selectedDay && isSameDay(day, selectedDay);
        
        return (
          <div
            key={day.toISOString()}
            className="px-2 py-4 text-center border-b border-gray-200 dark:border-gray-700 transition-colors duration-200"
          >
            <div className={`text-[14px] ${isSelected ? 'text-[#407BFF] dark:text-[#60a5fa]' : 'text-[#6B6B6B] dark:text-gray-400'} transition-colors duration-200`}>
              {format(day, 'EEE')}
            </div>
            <div className={`text-sm font-semibold ${isSelected ? 'text-[#407BFF] dark:text-[#60a5fa]' : 'text-gray-800 dark:text-gray-200'} transition-colors duration-200`}>
              {format(day, 'd')}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default WeekHeader;