import React, { useState, useEffect } from 'react';
import { format, areIntervalsOverlapping } from 'date-fns';
import ColorPicker from './ColorPicker';
import { Task, TaskUI } from '../types';
import { useDebounce } from '../lib/hooks';

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDate: Date;
  onSave: (taskData: {
    id?: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    color: string;
    status: 'pending' | 'in_progress' | 'completed' | 'cancelled';
  }) => void;
  existingTask: TaskUI | null;
  theme?: 'light' | 'dark';
  allTasks: TaskUI[];
}

const TaskModal: React.FC<TaskModalProps> = ({
  isOpen,
  onClose,
  selectedDate,
  onSave,
  existingTask,
  theme = 'light',
  allTasks = []
}) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isAllDay, setIsAllDay] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>('09:00');
  const [endTime, setEndTime] = useState<string>('10:00');
  const [selectedColor, setSelectedColor] = useState<string>('blue');
  const [showError, setShowError] = useState<boolean>(false);
  const [status, setStatus] = useState<'pending' | 'in_progress' | 'completed' | 'cancelled'>('pending');
  const [showConflictError, setShowConflictError] = useState<boolean>(false);
  const [conflictingTask, setConflictingTask] = useState<TaskUI | null>(null);
  
  const debouncedTitle = useDebounce(title, 300);

  useEffect(() => {
    if (isOpen) {
      if (existingTask) {
        setTitle(existingTask.title || '');
        setDescription(existingTask.description || '');
        
        const isAllDayEvent = 
          existingTask.start.getHours() === 0 &&
          existingTask.start.getMinutes() === 0 &&
          existingTask.end.getHours() === 23 &&
          existingTask.end.getMinutes() === 59;

        setIsAllDay(isAllDayEvent);
        setSelectedColor(existingTask.color);
        setStatus(existingTask.status);

        if (!isAllDayEvent) {
          setStartTime(format(existingTask.start, 'HH:mm'));
          setEndTime(format(existingTask.end, 'HH:mm'));
        }
      } else {
        setTitle('');
        setDescription('');
        setIsAllDay(false);
        setStatus('pending');
        
        const initialStart = format(selectedDate, 'HH:mm');
        const endDate = new Date(selectedDate);
        endDate.setHours(endDate.getHours() + 1);
        
        setStartTime(initialStart);
        setEndTime(format(endDate, 'HH:mm'));
        setSelectedColor('blue');
      }
      
      setShowError(false);
      setShowConflictError(false);
      setConflictingTask(null);
    }
  }, [isOpen, existingTask, selectedDate]);

  const handleSave = () => {
    if (!title.trim()) {
      alert('Please enter a task title');
      return;
    }

    const start = new Date(selectedDate);
    const end = new Date(selectedDate);

    if (isAllDay) {
      start.setHours(0, 0, 0);
      end.setHours(23, 59, 59);
    } else {
      const [startHour, startMinute] = startTime.split(':').map(Number);
      const [endHour, endMinute] = endTime.split(':').map(Number);
      
      if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) {
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
        return;
      }
      
      start.setHours(startHour, startMinute);
      end.setHours(endHour, endMinute);
    }
    
    const conflict = allTasks.find(task => {
      if (existingTask && task.id === existingTask.id) {
        return false;
      }
      
      const sameDay = 
        task.start.getDate() === start.getDate() &&
        task.start.getMonth() === start.getMonth() &&
        task.start.getFullYear() === start.getFullYear();
        
      if (!sameDay) return false;
      
      return areIntervalsOverlapping(
        { start: task.start, end: task.end },
        { start, end }
      );
    });
    
    if (conflict) {
      setConflictingTask(conflict);
      setShowConflictError(true);
      setTimeout(() => setShowConflictError(false), 4000);
      return;
    }

    onSave({
      id: existingTask?.id,
      title,
      description,
      start,
      end,
      color: selectedColor,
      status: existingTask ? status : 'pending'
    });
    
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md relative transition-colors duration-200">
        {showError && (
          <div className="absolute -top-20 left-0 right-0 mx-auto w-full max-w-md transform transition-all duration-300 ease-in-out opacity-100 translate-y-0">
            <div className="bg-red-100 dark:bg-red-900/30 border border-red-400 dark:border-red-700 text-red-700 dark:text-red-300 px-4 py-3 rounded relative flex items-center justify-between transition-colors duration-200">
              <span className="block sm:inline">End time must be after start time</span>
              <button
                onClick={() => setShowError(false)}
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                aria-label="Close error message"
              >
                <span className="text-red-500 dark:text-red-300 hover:text-red-700 dark:hover:text-red-100 transition-colors duration-200">×</span>
              </button>
            </div>
          </div>
        )}
        
        {showConflictError && conflictingTask && (
          <div className="absolute -top-20 left-0 right-0 mx-auto w-full max-w-md transform transition-all duration-300 ease-in-out opacity-100 translate-y-0">
            <div className="bg-orange-100 dark:bg-orange-900/30 border border-orange-400 dark:border-orange-700 text-orange-700 dark:text-orange-300 px-4 py-3 rounded relative flex items-center justify-between transition-colors duration-200">
              <div>
                <p className="font-medium">Time slot conflict detected!</p>
                <p className="text-sm">
                  "{conflictingTask.title}" is already scheduled from {format(conflictingTask.start, 'h:mm a')} to {format(conflictingTask.end, 'h:mm a')}
                </p>
              </div>
              <button
                onClick={() => setShowConflictError(false)}
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                aria-label="Close error message"
              >
                <span className="text-orange-500 dark:text-orange-300 hover:text-orange-700 dark:hover:text-orange-100 transition-colors duration-200">×</span>
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white transition-colors duration-200">
            {existingTask ? 'Edit Task' : 'Create Task'} - {format(selectedDate, 'MMMM d, yyyy')}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors duration-200"
            aria-label="Close modal"
          >
            ✕
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label htmlFor="task-title" className="block font-medium text-gray-700 dark:text-gray-300 mb-1 transition-colors duration-200">Title</label>
            <input
              id="task-title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
              placeholder="Enter task title"
            />
          </div>

          <div>
            <label htmlFor="task-description" className="block font-medium text-gray-700 dark:text-gray-300 mb-1 transition-colors duration-200">Description</label>
            <textarea
              id="task-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
              rows={3}
              placeholder="Enter task description (optional)"
            />
          </div>

          <div className="flex items-center justify-between">
            <label className="font-medium text-gray-700 dark:text-gray-300 transition-colors duration-200">All Day</label>
            <div 
              className={`w-12 h-6 rounded-full p-1 cursor-pointer transition-colors duration-200 ${
                isAllDay ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
              }`}
              onClick={() => setIsAllDay(!isAllDay)}
            >
              <div
                className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                  isAllDay ? 'translate-x-6' : ''
                }`}
              />
            </div>
          </div>

          {!isAllDay && (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="start-time" className="block font-medium text-gray-700 dark:text-gray-300 mb-1 transition-colors duration-200">Start Time</label>
                <input
                  id="start-time"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                />
              </div>
              <div>
                <label htmlFor="end-time" className="block font-medium text-gray-700 dark:text-gray-300 mb-1 transition-colors duration-200">End Time</label>
                <input
                  id="end-time"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                />
              </div>
            </div>
          )}

          <div>
            <label className="block font-medium text-gray-700 dark:text-gray-300 mb-2 transition-colors duration-200">Color</label>
            <ColorPicker
              selectedColor={selectedColor}
              onColorSelect={setSelectedColor}
              theme={theme}
            />
          </div>

          {existingTask && (
            <div>
              <label htmlFor="task-status" className="block font-medium text-gray-700 dark:text-gray-300 mb-1 transition-colors duration-200">Status</label>
              <select
                id="task-status"
                value={status}
                onChange={(e) => setStatus(e.target.value as 'pending' | 'in_progress' | 'completed' | 'cancelled')}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
              >
                <option value="pending">Pending</option>
                <option value="in_progress">In Progress</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
          )}

          <div className="flex justify-end space-x-2 pt-4">
            <button
              className="px-4 py-2 text-sm rounded bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 text-sm text-white rounded bg-blue-500 hover:bg-blue-600 transition-colors duration-200"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;