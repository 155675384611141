import React, { useState } from 'react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

interface AuthFormsProps {
  onSuccess?: () => void;
}

const AuthForms: React.FC<AuthFormsProps> = ({ onSuccess }) => {
  const [showLogin, setShowLogin] = useState<boolean>(true);
  
  return (
    <div className="w-full max-w-md mx-auto">
      <div className="flex justify-center mb-6">
        <div className="w-full flex rounded-md overflow-hidden">
          <button
            className={`w-1/2 py-2 text-center ${
              showLogin
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => setShowLogin(true)}
            type="button"
          >
            Login
          </button>
          <button
            className={`w-1/2 py-2 text-center ${
              !showLogin
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => setShowLogin(false)}
            type="button"
          >
            Sign Up
          </button>
        </div>
      </div>
      
      {showLogin ? (
        <LoginForm onSuccess={onSuccess} />
      ) : (
        <SignupForm onSuccess={() => setShowLogin(true)} />
      )}
    </div>
  );
};

export default AuthForms;