import React, { useState, useEffect, useCallback } from 'react';
import { addDays, startOfWeek } from 'date-fns';
import { IoClose } from 'react-icons/io5';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { fetchTasks, createTask, updateTask, deleteTask } from '../lib/features/tasks/tasksSlice';
import MiniCalendar from './MiniCalendar';
import WeekHeader from './WeekHeader';
import TimeGrid from './TimeGrid';
import TaskModal from './TaskModal';
import Loader from './Loader';
import { TaskUI } from '../types';
import { 
  convertTasksForUI, 
  uiToCreateTaskRequest, 
  uiToUpdateTaskRequest 
} from '../utils/taskHelpers';

interface CalendarProps {
  onClose?: () => void;
  theme?: 'light' | 'dark';
}

const Calendar: React.FC<CalendarProps> = ({ onClose, theme = 'light' }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [editingTask, setEditingTask] = useState<TaskUI | null>(null);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { tasks, loading, error } = useAppSelector((state) => state.tasks);
  
  const uiTasks = convertTasksForUI(tasks);

  const colors = {
    blue: 'bg-blue-100 text-blue-900',
    pink: 'bg-pink-100 text-pink-900',
    purple: 'bg-purple-100 text-purple-900',
    green: 'bg-green-100 text-green-900',
    orange: 'bg-orange-100 text-orange-900',
    red: 'bg-red-100 text-red-900'
  };

  const weekStart = startOfWeek(selectedDate);
  const displayedWeek = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  useEffect(() => {
    dispatch(fetchTasks());
  }, [dispatch]);

  const handleTimeSlotClick = (time: Date) => {
    setSelectedTime(time);
    setSelectedDate(time);
    setIsModalOpen(true);
  };

  const handleSaveTask = async (taskData: {
    id?: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    color: string;
    status: 'pending' | 'in_progress' | 'completed' | 'cancelled';
  }) => {
    try {
      if (editingTask) {
        const updateData = uiToUpdateTaskRequest({
          title: taskData.title,
          description: taskData.description,
          start: taskData.start,
          end: taskData.end,
          color: taskData.color,
          status: taskData.status
        });
        
        await dispatch(updateTask({ 
          id: taskData.id || editingTask.id, 
          taskData: updateData 
        }));
      } else {
        const createData = uiToCreateTaskRequest({
          title: taskData.title,
          description: taskData.description,
          start: taskData.start,
          end: taskData.end,
          color: taskData.color,
          status: 'pending'
        });
        
        await dispatch(createTask(createData));
      }
      
      setIsModalOpen(false);
      setEditingTask(null);
    } catch (err) {
      console.error('Error saving task:', err);
      alert('Failed to save task');
    }
  };

  const handleTaskUpdate = (task: TaskUI) => {
    setEditingTask(task);
    setSelectedTime(task.start);
    setSelectedDate(task.start);
    setIsModalOpen(true);
  };

  const handleTaskDelete = async (taskId: string) => {
    try {
      await dispatch(deleteTask(taskId));
    } catch (err) {
      console.error('Error deleting task:', err);
      alert('Failed to delete task');
    }
  };

  const handleTaskStatusChange = async (taskId: string, newStatus: 'pending' | 'in_progress' | 'completed' | 'cancelled') => {
    try {
      await dispatch(updateTask({
        id: taskId,
        taskData: { status: newStatus }
      }));
    } catch (err) {
      console.error('Error updating task status:', err);
      alert('Failed to update task status');
    }
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    if (date.getMonth() !== currentMonth.getMonth()) {
      setCurrentMonth(date);
    }
  };

  const handleRetry = () => {
    dispatch(fetchTasks());
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <p className="text-red-500 dark:text-red-400 mb-4 transition-colors duration-200">Error: {error}</p>
          <button
            onClick={handleRetry}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="font-sans flex w-full h-full bg-white dark:bg-gray-900 relative transition-colors duration-200">
      {loading && <Loader message="Loading your schedule..." theme={theme} />}
      
      <div className="p-4 border-r border-gray-200 dark:border-gray-700 transition-colors duration-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl ml-8 pb-4 pt-2 font-semibold text-gray-800 dark:text-white transition-colors duration-200">Task Scheduler</h2>
        </div>
        <MiniCalendar
          selectedDate={selectedDate}
          onDateSelect={handleDateSelect}
          currentMonth={currentMonth}
          onMonthChange={setCurrentMonth}
          displayedWeek={displayedWeek}
          theme={theme}
        />
      </div>

      <div className="overflow-auto h-full w-full">
        <div className="flex justify-between items-center p-4">
          <h2 className="text-[18px] ml-10 pt-1 text-gray-800 dark:text-white transition-colors duration-200">
            Your Tasks
          </h2>
          {onClose && (
            <button
              onClick={onClose}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 p-1 bg-gray-100 dark:bg-gray-800 rounded-full transition-colors duration-200"
              aria-label="Close calendar"
            >
              <IoClose size={17} />
            </button>
          )}
        </div>
        <WeekHeader weekDays={displayedWeek} selectedDay={selectedDate} theme={theme} />
        <TimeGrid
          weekDays={displayedWeek}
          tasks={uiTasks}
          onTimeSlotClick={handleTimeSlotClick}
          colors={colors}
          onTaskUpdate={handleTaskUpdate}
          onTaskDelete={handleTaskDelete}
          onTaskStatusChange={handleTaskStatusChange}
          selectedDay={selectedDate}
          theme={theme}
        />
      </div>

      {selectedTime && (
        <TaskModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditingTask(null);
          }}
          selectedDate={selectedTime}
          onSave={handleSaveTask}
          existingTask={editingTask}
          theme={theme}
          allTasks={uiTasks}
        />
      )}
    </div>
  );
};

export default Calendar;